<template>
  <div class="projectintroduct">
    <div class="title">{{introductlist.title || ""}}</div>
    <TitleLine width="150px"></TitleLine>
    <div class="introductcontent">
      <div class="intcontent">
        <p v-html="introductlist.text?introductlist.text:''"></p>
        <!-- <p>中国红十字会顺应社会需要、践行自身宗旨、接受政府委托、依据法律授权逐步参与遗体和人体器官捐赠，作出了积极的贡献。
          2007年3月国务院《人体器官移植条例》颁布，2010年3月中国红十字会总会接受原卫生部委托在全国试点启动公民逝世后器官捐赠试点工作，
          2013年2月在全国全面推开，2015年实现了器官来源的根本转型，公民捐赠成为唯一合法的器官来源渠道，
          2017年2月全国人大常委会通过《中华人民共和国红十字会法》修订案，
          将“参与、推动遗体和人体器官捐赠工作”明确为红十字会的职责。 
          </p>
        <p>
          中国人体器官捐赠管理中心（以下简称“器官中心”）
          是2012年7月6日经中央机构编制委员会办公室《关于设立中国人体器官捐赠管理中心的批复》批准成立，
          2013年1月正式组建开展工作，是中国红十字会总会直属的中央财政补助事业单位。
          主要职责为：负责参与人体器官捐赠的宣传动员、
          报名登记、捐赠见证、公平分配、救助激励、缅怀纪念及信息平台建设等相关工作。
          内设4个处级部室：办公室（财务）、捐赠部、宣传部、信息部。
        </p>
        <p>
          器官捐赠是挽救器官衰竭患者生命、服务医学发展、展现人性光辉、传递人间大爱、体现社会进步文明的高尚事业，
          是一项社会系统工程，需要全社会的参与和支持！
        </p> -->
      </div>
      <div class="background-right"></div>
      <div class="background-left"></div>
    </div>
  </div>
</template>

<script>
import apiUrls from '@api'
import TitleLine from '@/components/TitleLine'
export default {
  components: {
    TitleLine
  },
  data () {
    return {
      introductlist : {}
    }
  },
  created () {
    this.getintroduct()
  },
  methods: {
    getintroduct() {
      apiUrls.gethumanintroduct({node: 'glxmjj'}).then(res=>{
      this.introductlist = res.results.data
      })
    }
  },
  name: 'Projectintroduct'
}
</script>

<style lang="less" scoped>
.projectintroduct {
    min-height: 598px;
    margin-top: 40px;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 0px 0px 18px 0px rgba(14,14,14, 0.13);
    .title {
      font-size: 20px;
      margin-top: 41px;
      font-weight: bolder;
      color: #555454;
      text-align: center;
    }
    .division {
      text-align: center;
    }
    .introductcontent {
      position: relative;
      width: 1038px;
      min-height: 400px;
      margin: 0 auto;
      border-radius: 8px; 
      box-shadow: 0px 0px 15px 1px rgba(215, 22, 13, 0.26);
      margin-top: 26px;
      margin-bottom: 89px;
      overflow: hidden;
      .intcontent {
        width: 953px;
        min-height: 341px;
        // border: 1px solid #555454;
        word-wrap: break-word;
        word-break: break-all;
        margin: 32px 41px 27px 44px;
        line-height: 34px;
        font-size: 16px;
        color: #666666;
        p {
          &:nth-child(1) {
            text-indent: 2em;
          }
          &:nth-child(2) {
            text-indent: 2em;
          }
          &:nth-child(3) {
            text-indent: 2em;
          }
        }
      }
      .background-right {
        width: 100px;
        height: 52px;
        bottom: 97px;
        right: -9px;
        position: absolute;
        background: url('../../../assets/img/bg-right.png') no-repeat center center;
        background-size: 100% 100%;
      }
      .background-left {
        width: 154px;
        height: 313px;
        position: absolute;
        top: 0;
        left: 0;
        background: url('../../../assets/img/bg-left.png') no-repeat center center;
        background-size: 100% 100%;
      }
    }
}
</style>
